var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"categories"},[_c('ConfirmDialog',{attrs:{"title":_vm.dialogTitle,"text":_vm.dialogText,"show":_vm.confirmDialog},on:{"close":_vm.closeDelDialog,"confirm":_vm.deleteCategory}}),_c('FullScreenDialog',{attrs:{"title":"Gerir Categoria","dialog":_vm.fullScreenDialog},on:{"close":_vm.closeFullScreenDialog}},[(_vm.fullScreenDialog)?_c('EditCategory',{attrs:{"editCategory":_vm.selectedCategory},on:{"close":_vm.closeFullScreenDialog}}):_vm._e()],1),_c('v-col',{staticClass:"text-end"},[_c('div',{staticClass:"my-2"},[_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":_vm.createCategory}},[_vm._v("Adicionar Categoria")])],1)]),_c('h3',[_vm._v("Categorias Ativas")]),_c('br'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.activeCategories,"items-per-page":10},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticStyle:{"height":"50px","width":"50px","margin":"5px"},attrs:{"src":item.icon,"aspect-ratio":"1"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"blue"},on:{"click":function($event){return _vm.editCategory(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.showDeleteDialog(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])}),_c('br'),_c('h3',[_vm._v("Categorias Inativas")]),_c('br'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.inactiveCategories,"items-per-page":10},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticStyle:{"height":"50px","width":"50px","margin":"5px"},attrs:{"src":item.icon,"aspect-ratio":"1"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"blue"},on:{"click":function($event){return _vm.editCategory(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.showDeleteDialog(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }