

























import Vue from "vue";

export default Vue.extend({
  name: "EditCategory",

  props: { editCategory: { type: Object, default: null } },

  data() {
    return {
      select: [
        { text: "Sim", value: true },
        { text: "Não", value: false }
      ],
      category: {
        id: 0,
        docId: "",
        active: false,
        description: "",
        icon: "",
        title: ""
      }
    };
  },

  methods: {
    saveCategory() {
      if (!this.validateForm()) return;

      if (this.editCategory) {
        this.$store.dispatch("category/updateCategory", this.category);
      } else {
        this.$store.dispatch("category/createCategory", this.category);
      }

      this.$emit("close");
    },

    validateForm() {
      if (!this.category.title) {
        return this.showError("Insira um nome para a categoria!");
      }

      if (this.isIntNumber(this.category.id)) {
        return this.showError("Id não é um número válido!");
      }
      this.category.id = parseInt(this.category.id.toString());

      return true;
    },

    isIntNumber(value: any) {
      return isNaN(parseInt(value));
    },

    showError(message: string) {
      this.$store.dispatch("app/showCustomError", message);
    }
  },

  mounted() {
    if (this.editCategory) {
      Object.assign(this.category, this.editCategory);
    }
  }
});
