











































































import Vue from "vue";
import { mapGetters } from "vuex";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import FullScreenDialog from "../../components/FullScreenDialog.vue";
import EditCategory from "./components/EditCategory.vue";
import { Category } from "../../store/types";

export default Vue.extend({
  name: "Category",

  components: {
    ConfirmDialog,
    FullScreenDialog,
    EditCategory
  },

  data() {
    return {
      confirmDialog: false as Boolean,
      fullScreenDialog: false as Boolean,
      dialogTitle: "" as string,
      dialogText: "" as string,
      selectedCategory: null as unknown | Category,
      headers: [
        { text: "Ícone", align: "center", value: "icon", width: "100" },
        { text: "Título", align: "start", value: "title" },
        {
          text: "Descrição",
          value: "description",
          align: "start",
          width: "400"
        },
        {
          text: "Ações",
          value: "actions",
          width: "110",
          align: "center",
          sortable: false
        }
      ] as Array<Object>
    };
  },

  computed: {
    ...mapGetters({
      activeCategories: "category/getActiveCategories",
      inactiveCategories: "category/getInactiveCategories"
    })
  },

  methods: {
    createCategory(): void {
      this.openFullScreenDialog();
    },

    editCategory(category: Category): void {
      this.selectedCategory = category;
      this.openFullScreenDialog();
    },

    deleteCategory(): void {
      this.$store.dispatch("category/deleteCategory", this.selectedCategory);
      this.closeDelDialog();
    },

    openFullScreenDialog(): void {
      this.fullScreenDialog = true;
    },

    closeFullScreenDialog(): void {
      this.selectedCategory = null;
      this.fullScreenDialog = false;
    },

    showDeleteDialog(category: Category): void {
      this.confirmDialog = true;
      this.dialogTitle = "Deseja apagar a categoria?";
      this.dialogText = "Essa ação não pode ser revertida!";
      this.selectedCategory = category;
    },

    closeDelDialog(): void {
      this.selectedCategory = null;
      this.confirmDialog = false;
    }
  },

  mounted() {
    this.$store.dispatch("category/fetchActiveCategory");
    this.$store.dispatch("category/fetchInactiveCategory");
  }
});
